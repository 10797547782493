import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'
import stats from './alpine/stats.js'
import "../styles/tailwind.css";
import "../styles/site.css";
import Wheel from "./wheel";

document.addEventListener("DOMContentLoaded", init);

async function init() {
    // Alpine
    window.Alpine = Alpine;
    Alpine.plugin(intersect);

    // "How it works" wheel.
    const wheel = new Wheel();

    // Social slider.
    const socialFeed = new Splide('#community', {
        perPage: 4,
        perMove: 1,
        gap: '2rem',
        lazyLoad: 'nearby',
        pagination: false,
        arrows: false,
        breakpoints: {
            693: {
                autoWidth: true,
                perPage: 1,
                snap: true,
                gap: '-2.5rem',
            },
            900: {
                autoWidth: true,
                perPage: 2,
                snap: true,
                gap: '1.2rem',
            },
            1024: {
                autoWidth: true,
                perPage: 2,
                snap: true,
                gap: '1.2rem',
            },
          }
    });
    socialFeed.mount();
    init_header_animation();
    view_video();
    init_stats();

    // Start Alpine
    Alpine.start();

    // Recurse over parent elements to get true offset.
    // @see https://stackoverflow.com/a/67036072
    const getOffsetTop = function(element) {
        if (!element) return 0;
        return getOffsetTop(element.offsetParent) + element.offsetTop;
    };

    // Wait half a second before scrolling so the page can be built.
    setTimeout( ()=> {
        if ( window.location.hash == '#form' ) {
            let formElm = document.getElementById('contact');
            window.scrollTo(0 , getOffsetTop(formElm));
        }
    }, 500)

}


function init_header_animation(){
    const showMenuToggle = document.querySelector('#menuToggle input[type=checkbox]');
    const header = document.querySelector('header');

    let prevScrollPos = window.pageYOffset;

    window.addEventListener('scroll', () => {
        // Do nothing if mobile menu is open
        if (showMenuToggle.checked) { return }

        const currentScrollPos = window.pageYOffset;
        // Show/hide the header based on scroll direction
        if (prevScrollPos > currentScrollPos) {
            // Scrolling up
            header.classList.remove('header--hidden');
        } else {
            // Scrolling down
            header.classList.add('header--hidden');
        }

        // Pause the video when scrolling stops
        clearTimeout(header.dataset.scrollTimeout);
        header.dataset.scrollTimeout = setTimeout(() => {
            // Check if scrolling has stopped for 500ms
            if (window.pageYOffset === currentScrollPos) {
            // Scrolling has stopped
            // Pause the video here
            }
        }, 500);

        prevScrollPos = currentScrollPos;
    });

}

function view_video(){
    const video = document.querySelector("#my-video");


const playButton = document.querySelector("#play-video-button");
playButton.addEventListener("click", function() {

  const videoPopup = document.querySelector("#video-popup");
  videoPopup.style.display = "block";

  video.play();
});


const closeButton = document.querySelector("#close-video-button");
closeButton.addEventListener("click", function() {

  const videoPopup = document.querySelector("#video-popup");
  videoPopup.style.display = "none";


  video.pause();
  video.currentTime = 0;
});


video.addEventListener("ended", function() {

  const videoPopup = document.querySelector("#video-popup");
  videoPopup.style.display = "none";


  video.pause();
  video.currentTime = 0;
});
}

function init_stats(){
    Alpine.data('stats', stats);
}
