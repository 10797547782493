export default (initialTarget = 0) => ({
    current: 0,
    target: initialTarget,
    time: 3000,

    init() {
        // This code will be executed before Alpine
        // initializes the rest of the component.
        var url = "https://docs.google.com/spreadsheets/d/e/2PACX-1vSvn7OZ3NUsJ4bPRYA9a9DlMDI2DqS6I93nLNV8G2VhN4abCz2wZYf9K8yRwSukdvzH-r-NmwEQTUsO/pub?gid=0&single=true&range=a2&output=csv";
        var xmlhttp = new XMLHttpRequest();
        var newTarget = 0;
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                newTarget = parseInt(xmlhttp.responseText.replace(/,/g, '')); // parse the number from the response text
                this.setTarget(newTarget);
            }
        }.bind(this);
        xmlhttp.open("GET", url, true);
        xmlhttp.send(null);
    },

    setTarget(newTarget = 0) {
        this.target = newTarget;
        // this.runCounter();
    },

    runCounter() {
        var start = this.current;
        const interval = Math.max(this.time / (this.target - start), 5);
        const step = (this.target - start) / (this.time / interval);
        const handle = setInterval(() => {
            if (this.current < this.target)
                this.current += step
            else {
                clearInterval(handle);
                this.current = this.target
            }
        }, interval)
    }
})
