import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";

import "../styles/wheel.scss";

export default class Wheel {
    constructor() {
        // Labels for each wheel piece
        this.labels = [
            "Get your coffee!",
            "Enjoy that great coffee taste",
            "Drop your cup in the office",
            "We collect your cups from the Drop Pods",
            "We wash and sanitise each cup for you",
            "We return the cups for the next use",
        ];

        this.snaps = [];
        this.wheel_tl = null;
        this.actor_tl = null;
        this.info_tl = null;
        this.st = null;
        this.ctaVisible = true;
        this.actorYou = true;
        this.activePiece = 0;
        this.activeSnap = 0;

        this.registerPlugins();
        this.createTimelines();
        this.setActor("you");
        this.createScrollTrigger();

        this.hookWheelPieces();
    }

    /**
     * Hook wheel piece clicks
     */
    hookWheelPieces() {
        const wheelPieces = document.getElementsByClassName("wheel-piece");
        Array.prototype.forEach.call(wheelPieces, (value, index) => {
            value.onclick = () => this.moveNext();
        });
    }

    moveNext() {
        window.scrollBy({
            left: 0,
            top: 300,
            behavior: "smooth",
        });
    }

    /**
     * Register GSAP plugins
     */
    registerPlugins() {
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(TextPlugin);
    }

    /**
     * Instantiate and setup GSAP timelines for animations
     */
    createTimelines() {
        // Create timeline for wheel
        const pieces = gsap.utils.toArray(".wheel-piece");
        const wheel_tl = gsap.timeline();

        // .from("#wheel-piece-1 .wheel-piece-inner", { rotation: 168 });
        const rotationStarts = [150, 90, 40, 30, 20, 10, 0];
        const rotationSlots = [390, 360, 330, 300, 232, 172, 90];
        const delaySlots = [null, 2, 6, 10, 10, 12, 14];
        const snaps = [0.025, 0.12, 0.215, 0.315, 0.485, 1, 1];

        let tlSets = [],
            tlTos = [];

        pieces.forEach((piece, index) => {
            const rotationStart = rotationStarts[index];
            const innerPiece = piece.querySelector(".wheel-piece-inner");
            wheel_tl
                .set(
                    piece,
                    {
                        rotation: -rotationStart,
                    },
                    "<"
                )
                .set(
                    innerPiece,
                    {
                        rotation: rotationStart,
                    },
                    "<"
                );
        });

        pieces.forEach((piece, index) => {
            const rotation = rotationSlots[index];
            let delay = delaySlots[index];
            delay = delay !== null ? "<" + delay + "%" : undefined;
            const rotationStart = rotationStarts[index];
            const innerPiece = piece.querySelector(".wheel-piece-inner");
            // console.log(index, rotation, delay);
            wheel_tl
                .addLabel("piece_" + (index + 1) + "_start")

                .to(
                    piece,
                    {
                        rotation: -rotation,
                    },
                    delay
                )
                .to(innerPiece, { rotation: rotation }, "<");
        });

        this.snaps = snaps;
        this.wheel_tl = wheel_tl;

        // Create timeline for info text
        const info_tl = gsap
            .timeline({
                paused: true,
                defaults: { duration: 1 },
            })
            .to(
                "#wheel-label",
                {
                    opacity: 0,
                },
                1
            )
            .to(
                "#wheel-label",
                {
                    opacity: 1,
                },
                2
            );

        this.info_tl = info_tl;
    }

    /**
     * Instantiate ScrollTrigger
     */
    createScrollTrigger() {
        const _this = this;
        // Setup scrolltrigger
        this.st = ScrollTrigger.create({
            animation: this.wheel_tl,
            trigger: "#how-it-works",
            start: "top top",
            end: "+=3000",
            scrub: 1,
            pin: true,
            toggleActions: "restart resume resume restart",

            snap: {
                snapTo: this.snaps,
                delay: 0.1,
                duration: 0.2,
                ease: "power4",
                onComplete: (self) => {
                    const { progress, direction, isActive } = self;
                    _this.handleScrollTriggerSnapComplete(
                        progress,
                        direction,
                        isActive
                    );
                },
            },
            onUpdate: (self) => {
                const { progress } = self;
                _this.handleScrollTriggerUpdate(progress);
            },
        });

        // this.wheel_tl.play("piece_2_start");
    }

    /**
     * Handle snap complete event for scrolltrigger
     *
     * @param {number} progress
     * @param {number} direction
     * @param {boolean} isActive
     */
    handleScrollTriggerSnapComplete(progress, direction, isActive) {
        let piece = 0;
        progress = Math.round(progress * 1000) / 1000.0;
        if (isActive && this.snaps.includes(progress)) {
            piece = this.snaps.indexOf(progress);
            this.activeSnap = this.snaps.indexOf(progress);
        } else if (!isActive) {
            if (progress === 0) {
                piece = 0;
            }
            if (progress === 1) {
                piece = 5;
            }
        }
        this.activePiece = piece;
        this.updateInfo();
    }

    setActor(actor) {
        const actorCercle = actor !== "you";
        gsap.to("#wheel-actor", {
            duration: 0.2,
            text: actorCercle ? "Cercle" : "You",
            color: actorCercle ? "#fff" : "#68C4CA",
            ease: "none",
        });
        this.actorYou = !actorCercle;
    }

    /**
     * Handle update event for scrolltrigger
     *
     * @param {number} progress
     */
    handleScrollTriggerUpdate(progress) {
        if (progress < 0.00001) {
            return;
        }
        if (progress >= this.snaps[1] * 0.5) {
            if (this.ctaVisible) {
                gsap.to("#wheel-cta", { opacity: 0 });
                this.ctaVisible = false;
            }
        } else {
            if (!this.ctaVisible) {
                gsap.to("#wheel-cta", { opacity: 1 });
                this.ctaVisible = true;
            }
        }

        if (!this.actorYou && progress <= this.snaps[2] * 1.1) {
            this.setActor("you");
        } else if (this.actorYou && progress >= this.snaps[3] * 0.95) {
            this.setActor("cercle");
        }
    }

    /**
     * Update info on RHS based on activePiece (0-indexed)
     */
    updateInfo() {
        const piece = this.activePiece;
        if (isNaN(piece) || piece < 0 || piece >= this.labels.length) {
            return;
        }
        const label = this.labels[piece];

        const piece1 = piece + 1;
        const image_target = document.getElementById("wheel-image").children[0];
        let image = image_target.src;
        const new_image = image.replace(/\/([0-9]+)\.png/, (match, p1, p2) => {
            return "/" + piece1.toString().replace(/[^0-9]/g, "") + ".png";
        });

        gsap.to("#wheel-info-slide #wheel-image", {
            // xPercent: 101,
            duration: 0.25,
        })
            .then(() => {
                image_target.src = new_image;
            })
            .finally(() =>
                gsap.to("#wheel-info-slide #wheel-image", {
                    xPercent: 0,
                    duration: 0.25,
                })
            );

        gsap.to("#wheel-info #wheel-label", {
            // yPercent: 1000,
            duration: 0.25,
        })
            .then(() => {
                document.getElementById("wheel-label").textContent = label;
            })
            .finally(() =>
                gsap.to("#wheel-info #wheel-label", {
                    yPercent: 0,
                    duration: 0.25,
                })
            );
    }
}
